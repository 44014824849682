import * as React from 'react';
import { IBaseStepProps } from "./Interfaces";
import StepWizard from 'react-step-wizard';
import { Navigation } from './Navigation'

import './Wizard.scss'

interface Props extends IBaseStepProps {
    children: JSX.Element | JSX.Element[];
}

export const Wizard: React.FC<Props> = (props) => {
    let custom = {
        enterRight: 'animated fadeIn',
        enterLeft : 'animated fadeIn',
        exitRight : 'animated fadeOut',
        exitLeft  : 'animated fadeOut'
    }

    return (
        <StepWizard
            nav={<Navigation/>}
            transitions={custom}>
            {props.children}
        </StepWizard>
    );
};  