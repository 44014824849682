import * as React from 'react';

export interface IStatsProps {
    currentStep?: number,
    firstStep?: any,
    goToStep?: any,
    lastStep?: any,
    nextStep?: any,
    previousStep?: any,
    totalSteps?: any,
    step?: any,
    finish?: Function,
    validate: Function
}

export const Stats: React.FC<IStatsProps> = ({
        currentStep,
        firstStep,
        goToStep,
        lastStep,
        nextStep,
        previousStep,
        totalSteps,
        step,
        finish,
        validate
    }) => {
    return (
        <div>
            <hr />
            { step > 1 &&
                <button className='btn btn-default btn-block' onClick={previousStep}>Go Back</button>
            }
            { step < totalSteps 
                ? <button className='btn btn-primary btn-block' onClick={(e) => { validate(step, nextStep, goToStep)}}>Continue</button>
                : <button className='btn btn-success btn-block' onClick={(e) => { if(finish) finish(e, goToStep); }}>Finish</button>
            }
        </div>
    );
}; 