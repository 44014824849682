import React, { ChangeEvent, MouseEvent } from 'react';
import _ from 'lodash';
import {CreateOrganisationRequest, OrganisationClient, OrganisationResponse} from '../ApiClient';
import { ApiConfig } from '../ApiConfig';
import '../styles/custom.scss'
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { Wizard } from '../components/wizard/Wizard'
import { WizardStep } from '../components/wizard/WizardStep'
import { actionCreators } from '../store/OrganisationState'
import { push } from 'connected-react-router'

interface INewOrganisation {
    name: string,
    tinNumber: string,
    email: string,
    address: string,
    country: string,
    OwnerName: string,
    OwnerEmail: string
}

export interface IState {
    PartnerId: string,
    loading: boolean,
    newOrganisation: INewOrganisation,
    validationError: INewOrganisation
}

interface IProps {
    PartnerId: string,
    history: any,
    dispatch: any
}

class CreateOrganisation extends React.Component<IProps, IState> {

    OrganisationClient = new OrganisationClient(new ApiConfig())
    
    constructor(props: IProps) {
        super(props);
        this.state = {
            PartnerId: this.props.PartnerId,
            newOrganisation: { name: '', OwnerName: '', tinNumber: '', email: '', address: '', country: '', OwnerEmail: '' },
            loading: true,
            validationError: { name: '', OwnerName: '', tinNumber: '', email: '', address: '', country: '', OwnerEmail: '' }
        }
    }

    public componentDidMount(): void {
    }

    handleOrganisationNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (this.state.newOrganisation) {
            this.setState({newOrganisation: {...this.state.newOrganisation, name: e.currentTarget.value}});
        } else {
            this.setState({newOrganisation: { name: e.currentTarget.value, address: '', email: '', country: '', tinNumber: '', OwnerName: '', OwnerEmail: '' }});
        }
    }

    handleOrganisationTinNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (this.state.newOrganisation) {
            this.setState({newOrganisation: {...this.state.newOrganisation, tinNumber: e.currentTarget.value}});
        } else {
            this.setState({newOrganisation: { name: '', address: '', country: '', email: '', tinNumber: e.currentTarget.value, OwnerName: '', OwnerEmail: '' }});
        }
    }

    handleOrganisationEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (this.state.newOrganisation) {
            this.setState({newOrganisation: {...this.state.newOrganisation, email: e.currentTarget.value}});
        } else {
            this.setState({newOrganisation: { name: '', address: '', country: '', tinNumber: '', email: e.currentTarget.value, OwnerName: '', OwnerEmail: '' }});
        }
    }

    handleOrganisationOwnerNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (this.state.newOrganisation) {
            this.setState({newOrganisation: {...this.state.newOrganisation, OwnerName: e.currentTarget.value}});
        } else {
            this.setState({newOrganisation: { name: '', address: '', country: '', email: '', OwnerName: e.currentTarget.value, tinNumber: '', OwnerEmail: '' }});
        }
    }

    handleOrganisationOwnerEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (this.state.newOrganisation) {
            this.setState({newOrganisation: {...this.state.newOrganisation, OwnerEmail: e.currentTarget.value}});
        } else {
            this.setState({newOrganisation: { name: '', address: '', country: '', email: '', OwnerEmail: e.currentTarget.value, OwnerName: '', tinNumber: '' }});
        }
    }

    handleOrganisationAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (this.state.newOrganisation) {
            this.setState({newOrganisation: {...this.state.newOrganisation, address: e.currentTarget.value}});
        } else {
            this.setState({newOrganisation: { name: '', address: e.currentTarget.value, country: '', email: '', OwnerEmail: '', OwnerName: '', tinNumber: '' }});
        }
    }

    handleOrganisationCountryChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (this.state.newOrganisation) {
            this.setState({newOrganisation: {...this.state.newOrganisation, country: e.currentTarget.value}});
        } else {
            this.setState({newOrganisation: { name: '', address: '', email: '', country: e.currentTarget.value, OwnerEmail: '', OwnerName: '', tinNumber: '' }});
        }
    }

    createOrganisation = (e: MouseEvent<HTMLButtonElement>, goToStep: Function) => {
        e.preventDefault();

        if (this.state.newOrganisation) {
            let p = this.state.newOrganisation;
            
            this.OrganisationClient
                .organisationPost(new CreateOrganisationRequest({ 
                    fullName: p.OwnerName, 
                    ownerEmail: p.OwnerEmail, 
                    name: p.name, 
                    email: p.email,
                    tinNumber: p.tinNumber, 
                    address: p.address, 
                    country: p.country 
                }))
                .then(response => {
                    goToStep(1)
                    this.setState({
                        newOrganisation: { name: '', address: '', email: '', country: '', OwnerName: '', tinNumber: '', OwnerEmail: '' }
                    })
                    this.OrganisationClient.organisationGet().then(Organisations => {
                        this.props.dispatch(actionCreators.setOrganisations(Organisations))
                        this.setState({ loading: false })
                    })

                    this.props.dispatch(push('/organisations'))
                })
        }
    }

    validateOrganisationInformationStep = (step: Number, nextStep: Function, goToStep: Function) => {
        let hasValidationError = false;
        let errors = { name: '', address: '',  email: '',country: '', OwnerName: '', tinNumber: '', OwnerEmail: '' };
        if (this.state.newOrganisation && this.state.newOrganisation.name === '') {
            errors.name = 'Missing name'
            hasValidationError = true;
        }

        if (this.state.newOrganisation && this.state.newOrganisation.tinNumber === '') {
            errors.tinNumber = 'Missing tin number'
            hasValidationError = true;
        }

        if (this.state.newOrganisation && this.state.newOrganisation.tinNumber !== '' && /^\d+$/.test(this.state.newOrganisation.tinNumber) === false) {
            errors.tinNumber = 'Tin number contains more than digits.'
            hasValidationError = true;
        }

        if (this.state.newOrganisation && this.state.newOrganisation.address === '') {
            errors.address = 'Missing address'
            hasValidationError = true;
        }

        if (this.state.newOrganisation && this.state.newOrganisation.country === '') {
            errors.country = 'Missing country'
            hasValidationError = true;
        }

        if (hasValidationError === true){
            this.setState({validationError: errors})
            goToStep(step)
            return;
        }

        this.OrganisationClient.tinNumber(this.state.newOrganisation.tinNumber)
            .then(response => {
                if (response){
                    errors.tinNumber = 'Tin number is already taken'
                    hasValidationError = true;
                }

                if (hasValidationError === true){
                    this.setState({validationError: errors})
                    goToStep(step)
                } else {
                    this.setState({validationError: { name: '', address: '',  email: '',country: '', OwnerName: '', tinNumber: '', OwnerEmail: '' }})
                    nextStep()
                }
            })
    }

    validateOwnerInformationStep = (step: Number, nextStep: Function, goToStep: Function) => {
        let hasValidationError = false;
        let errors = { name: '', address: '',  email: '',country: '', OwnerName: '', tinNumber: '', OwnerEmail: '' };
        if (this.state.newOrganisation && this.state.newOrganisation.OwnerName === '') {
            errors.OwnerName = 'Missing name'
            hasValidationError = true;
        }

        if (this.state.newOrganisation && this.state.newOrganisation.OwnerEmail.indexOf('@') === -1) {
            errors.OwnerEmail = 'Missing @ in email'
            hasValidationError = true;
        }

        if (hasValidationError === true){
            this.setState({validationError: errors})
            goToStep(step)
        } else {
            this.setState({validationError: { name: '', address: '',  email: '',country: '', OwnerName: '', tinNumber: '', OwnerEmail: '' }})
            nextStep()
        }
    }
    
    public render() {
        return (
            <React.Fragment>
                <h1>Create organisation</h1>
                <Wizard>
                    <WizardStep step={1} header="Organisation information" validate={this.validateOrganisationInformationStep}>
                        <label>Name</label>
                        <input required type='text' className='form-control' name='organisationName' placeholder='Name' 
                            onChange={this.handleOrganisationNameChange} value={this.state.newOrganisation && this.state.newOrganisation.name} />
                        { this.state.validationError && this.state.validationError.name.length > 0 &&
                            <p className="alert alert-danger">{this.state.validationError.name}</p> }
                        <label>Tin number</label>
                        <input required type='text' className='form-control' name='tinNumber' placeholder='Tin number'
                            onChange={this.handleOrganisationTinNumberChange} value={this.state.newOrganisation && this.state.newOrganisation.tinNumber} />
                        { this.state.validationError && this.state.validationError.tinNumber.length > 0 &&
                            <p className="alert alert-danger">{this.state.validationError.tinNumber}</p> }
                        <label>Email</label>
                        <input required type='text' className='form-control' name='email' placeholder='Email'
                            onChange={this.handleOrganisationEmailChange} value={this.state.newOrganisation && this.state.newOrganisation.email} />
                        { this.state.validationError && this.state.validationError.email.length > 0 &&
                            <p className="alert alert-danger">{this.state.validationError.email}</p> }
                        <label>Address</label>
                        <input required type='text' className='form-control' name="organisationAddress" placeholder='Address'
                            onChange={this.handleOrganisationAddressChange} value={this.state.newOrganisation && this.state.newOrganisation.address} />
                        { this.state.validationError && this.state.validationError.address.length > 0 &&
                            <p className="alert alert-danger">{this.state.validationError.address}</p> }
                        <label>Country</label>
                        <input required type='text' className='form-control' name="organisationCountry" placeholder='Country'
                            onChange={this.handleOrganisationCountryChange} value={this.state.newOrganisation && this.state.newOrganisation.country} />
                        { this.state.validationError && this.state.validationError.country.length > 0 &&
                            <p className="alert alert-danger">{this.state.validationError.country}</p> }
                    </WizardStep>
                    <WizardStep step={2} header="Owner information" validate={this.validateOwnerInformationStep}>
                        <label>Name</label>
                        <input type='text' className='form-control' name='name' placeholder='Name' 
                            onChange={this.handleOrganisationOwnerNameChange} value={this.state.newOrganisation && this.state.newOrganisation.OwnerName}/>
                        { this.state.validationError && this.state.validationError.OwnerName.length > 0 &&
                            <p className="alert alert-danger">{this.state.validationError.OwnerName}</p> }
                        <label>Email</label>
                        <input type='email' className='form-control' name='email' placeholder='Email' 
                        onChange={this.handleOrganisationOwnerEmailChange} value={this.state.newOrganisation && this.state.newOrganisation.OwnerEmail}/>
                        { this.state.validationError && this.state.validationError.OwnerEmail.length > 0 &&
                            <p className="alert alert-danger">{this.state.validationError.OwnerEmail}</p> }
                    </WizardStep>
                    <WizardStep step={3} finish={this.createOrganisation} header="Review information" validate={() => true}>
                        {this.state.newOrganisation && <p>Organisation name: {this.state.newOrganisation.name}</p>}
                        {this.state.newOrganisation && <p>Tin number: {this.state.newOrganisation.tinNumber}</p>}
                        {this.state.newOrganisation && <p>Email: {this.state.newOrganisation.email}</p>}
                        {this.state.newOrganisation && <p>Address: {this.state.newOrganisation.address}</p>}
                        {this.state.newOrganisation && <p>Country: {this.state.newOrganisation.country}</p>}
                        {this.state.newOrganisation && <p>Owner name: {this.state.newOrganisation.OwnerName}</p>}
                        {this.state.newOrganisation && <p>Owner email: {this.state.newOrganisation.OwnerEmail}</p>}
                    </WizardStep>
                </Wizard>
            </React.Fragment>
        )
    }
}

const mapState = (state: ApplicationState) => ({
    PartnerId: state && state.partner && state.partner.currentPartnerId
} as IProps)

export default connect(mapState)(CreateOrganisation)