import * as React from 'react'
import "./emptyState.scss";

interface Props {
    text?: string;
}
export const EmptyState: React.FC<Props> = ({ text }) => {
    return (
        <div className="empty-state">
            <img alt="" src="dust.png" />
            <p>{text}</p>
        </div>
    );
};  