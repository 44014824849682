import React, { ChangeEvent, MouseEvent } from 'react';
import _ from 'lodash';
import {CreatePartnerRequest, PartnerClient, PartnerResponse} from '../ApiClient';
import { ApiConfig } from '../ApiConfig';
import '../styles/custom.scss'
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { Wizard } from '../components/wizard/Wizard'
import { WizardStep } from '../components/wizard/WizardStep'
import { actionCreators } from '../store/PartnerState'
import { push } from 'connected-react-router'

interface INewPartner {
    name: string,
    tinNumber: string,
    email: string,
    OwnerName: string,
    OwnerEmail: string
}

export interface IState {
    partnerId: string,
    accessiblePartners: PartnerResponse[],
    partners: PartnerResponse[],
    loading: boolean,
    newPartner: INewPartner,
    validationError: INewPartner
}

interface IProps {
    partnerId: string,
    partners: PartnerResponse[],
    history: any,
    dispatch: any
}

class CreatePartner extends React.Component<IProps, IState> {

    partnerClient = new PartnerClient(new ApiConfig())
    
    constructor(props: IProps) {
        super(props);
        this.state = {
            partnerId: this.props.partnerId,
            accessiblePartners : this.props.partners,
            partners: [],
            newPartner: { name: '', email: '', OwnerName: '', tinNumber: '', OwnerEmail: '' },
            loading: true,
            validationError: { name: '', email: '', OwnerName: '', tinNumber: '', OwnerEmail: '' }
        }
    }

    public componentDidMount(): void {
        this.partnerClient.subPartners().then(response => {
            this.setState({ partners: response, loading: false });
        })
    }

    handlePartnerNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (this.state.newPartner) {
            this.setState({newPartner: {...this.state.newPartner, name: e.currentTarget.value}});
        } else {
            this.setState({newPartner: { name: e.currentTarget.value, email: '', tinNumber: '', OwnerName: '', OwnerEmail: '' }});
        }
    }

    handlePartnerTinNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (this.state.newPartner) {
            this.setState({newPartner: {...this.state.newPartner, tinNumber: e.currentTarget.value}});
        } else {
            this.setState({newPartner: { name: '', tinNumber: e.currentTarget.value, email: '', OwnerName: '', OwnerEmail: '' }});
        }
    }

    handlePartnerEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (this.state.newPartner) {
            this.setState({newPartner: {...this.state.newPartner, email: e.currentTarget.value}});
        } else {
            this.setState({newPartner: { name: '', email: e.currentTarget.value, tinNumber: '', OwnerName: '', OwnerEmail: '' }});
        }
    }

    handlePartnerOwnerNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (this.state.newPartner) {
            this.setState({newPartner: {...this.state.newPartner, OwnerName: e.currentTarget.value}});
        } else {
            this.setState({newPartner: { name: '', OwnerName: e.currentTarget.value, email: '', tinNumber: '', OwnerEmail: '' }});
        }
    }

    handlePartnerOwnerEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (this.state.newPartner) {
            this.setState({newPartner: {...this.state.newPartner, OwnerEmail: e.currentTarget.value}});
        } else {
            this.setState({newPartner: { name: '', email: '', OwnerEmail: e.currentTarget.value, OwnerName: '', tinNumber: '' }});
        }
    }

    createPartner = (e: MouseEvent<HTMLButtonElement>, goToStep: Function) => {
        e.preventDefault();

        if (this.state.newPartner) {
            let p = this.state.newPartner;
            
            this.partnerClient
                .partnerPost(new CreatePartnerRequest({ fullName: p.OwnerName, email: p.OwnerEmail, name: p.name, tinNumber: p.tinNumber, partnerEmail: p.email }))
                .then(response => {
                    goToStep(1)
                    this.setState({
                        newPartner: { name: '', email: '', OwnerName: '', tinNumber: '', OwnerEmail: '' }
                    })
                    this.partnerClient.partnerGet().then(partners => {
                        this.props.dispatch(actionCreators.setPartners(partners))
                        this.setState({ loading: false })
                    })

                    this.props.dispatch(push('/partners'))
                })
        }
    }

    validatePartnerInformationStep = (step: Number, nextStep: Function, goToStep: Function) => {
        let hasValidationError = false;
        let errors = { name: '', email: '', OwnerName: '', tinNumber: '', OwnerEmail: '' };
        if (this.state.newPartner && this.state.newPartner.name === '') {
            errors.name = 'Missing name'
            hasValidationError = true;
        }

        if (this.state.newPartner && this.state.newPartner.tinNumber === '') {
            errors.tinNumber = 'Missing tin number'
            hasValidationError = true;
        }

        if (this.state.newPartner && /^\d+$/.test(this.state.newPartner.tinNumber) === false) {
            errors.tinNumber = 'Tin number contains more than digits.'
            hasValidationError = true;
        }

        if (this.state.newPartner && this.state.newPartner.email === ''
            || this.state.newPartner.email.indexOf('@') === -1) {
            errors.tinNumber = 'Missing partner email'
            hasValidationError = true;
        }

        if (hasValidationError === true){
            this.setState({validationError: errors})
            goToStep(step)
            return;
        }

        this.partnerClient.tinNumber(this.state.newPartner.tinNumber)
            .then(response => {
                if (response){
                    errors.tinNumber = 'Tin number is already taken'
                    hasValidationError = true;
                }

                if (hasValidationError === true){
                    this.setState({validationError: errors})
                    goToStep(step)
                } else {
                    this.setState({validationError: { name: '', email: '', OwnerName: '', tinNumber: '', OwnerEmail: '' }})
                    nextStep()
                }
            })
    }

    validateOwnerInformationStep = (step: Number, nextStep: Function, goToStep: Function) => {
        let hasValidationError = false;
        let errors = { name: '', email: '', OwnerName: '', tinNumber: '', OwnerEmail: '' };
        if (this.state.newPartner && this.state.newPartner.OwnerName === '') {
            errors.OwnerName = 'Missing name'
            hasValidationError = true;
        }

        if (this.state.newPartner && this.state.newPartner.OwnerEmail.indexOf('@') === -1) {
            errors.OwnerEmail = 'Missing @ in email'
            hasValidationError = true;
        }

        if (hasValidationError === true){
            this.setState({validationError: errors})
            goToStep(step)
        } else {
            this.setState({validationError: { name: '', email: '', OwnerName: '', tinNumber: '', OwnerEmail: '' }})
            nextStep()
        }
    }
    
    public render() {
        return (
            <React.Fragment>
                <h1>Create partner</h1>
                <Wizard>
                    <WizardStep step={1} header="Partner information" validate={this.validatePartnerInformationStep}>
                        <label>Name</label>
                        <input required type='text' className='form-control' name='partnerName' placeholder='Name' 
                            onChange={this.handlePartnerNameChange} value={this.state.newPartner && this.state.newPartner.name} />
                        { this.state.validationError && this.state.validationError.name.length > 0 &&
                            <p className="alert alert-danger">{this.state.validationError.name}</p> }
                        <label>Tin number</label>
                        <input required type='text' className='form-control' name='tinNumber' placeholder='Tin number'
                            onChange={this.handlePartnerTinNumberChange} value={this.state.newPartner && this.state.newPartner.tinNumber} />
                        <label>Email</label>
                        <input required type='text' className='form-control' name='partnerEmail' placeholder='Email' 
                            onChange={this.handlePartnerEmailChange} value={this.state.newPartner && this.state.newPartner.email} />
                        { this.state.validationError && this.state.validationError.email.length > 0 &&
                            <p className="alert alert-danger">{this.state.validationError.email}</p> }
                        { this.state.validationError && this.state.validationError.tinNumber.length > 0 &&
                            <p className="alert alert-danger">{this.state.validationError.tinNumber}</p> }
                    </WizardStep>
                    <WizardStep step={2} header="Owner information" validate={this.validateOwnerInformationStep}>
                        <label>Name</label>
                        <input type='text' className='form-control' name='name' placeholder='Name' 
                            onChange={this.handlePartnerOwnerNameChange} value={this.state.newPartner && this.state.newPartner.OwnerName}/>
                        { this.state.validationError && this.state.validationError.OwnerName.length > 0 &&
                            <p className="alert alert-danger">{this.state.validationError.OwnerName}</p> }
                        <label>Email</label>
                        <input type='email' className='form-control' name='email' placeholder='Email' 
                        onChange={this.handlePartnerOwnerEmailChange} value={this.state.newPartner && this.state.newPartner.OwnerEmail}/>
                        { this.state.validationError && this.state.validationError.OwnerEmail.length > 0 &&
                            <p className="alert alert-danger">{this.state.validationError.OwnerEmail}</p> }
                    </WizardStep>
                    <WizardStep step={3} finish={this.createPartner} header="Review information" validate={() => true}>
                        {this.state.newPartner && <p>Partner name: {this.state.newPartner.name}</p>}
                        {this.state.newPartner && <p>Tin number: {this.state.newPartner.tinNumber}</p>}
                        {this.state.newPartner && <p>Email: {this.state.newPartner.email}</p>}
                        {this.state.newPartner && <p>Owner name: {this.state.newPartner.OwnerName}</p>}
                        {this.state.newPartner && <p>Owner email: {this.state.newPartner.OwnerEmail}</p>}
                    </WizardStep>
                </Wizard>
            </React.Fragment>
        )
    }
}

const mapState = (state: ApplicationState) => ({
    partnerId: state && state.partner && state.partner.currentPartnerId,
    partners: state && state.partner && state.partner.partners
} as IProps)

export default connect(mapState)(CreatePartner)