import * as React from 'react'
import { Link } from 'react-router-dom'

interface Props {
    to: string,
    text: string;
}
export const LinkButton: React.FC<Props> = ({ to, text }) => {
    return (
        <Link className="btn btn-info" to={to}>{'+ ' +text}</Link>
    );
};  