import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';
import {CaseClient, CaseResponse, DeleteCaseRequest, UpdateCaseRequest, UpdateUserRequest, UserClient, UserResponse} from '../ApiClient';
import { ApiConfig } from '../ApiConfig';
import '../styles/custom.scss'
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { Loading } from '../components/loading/loading'
import { FormGroup, Button, Form, Input, Label, Table, Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { LinkButton } from '../components/generic/link-button';

export interface IState {
    partnerId: string,
    users : UserResponse[],
    loading: boolean,
    email: string,
    name: string,
    displayInviteBox: boolean

}

interface IProps {
    partnerId: string,
    history: any,
}

class Settings extends React.Component<IProps, IState> {

    userClient = new UserClient(new ApiConfig())
    
    constructor(props: IProps) {
        super(props);
        this.state = {
            partnerId: this.props.partnerId,
            users : [],
            loading: true,
            email: '',
            name: '',
            displayInviteBox: false

        }
    }

    public componentDidMount(): void {
        this.userClient.partner().then(response => {
            this.setState({ users: response, loading: false });
        })
    }
    notifyToast = (action: string) =>{
        toast(action, {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
  }
    handleNotify = (user: any) =>{
        this.userClient.user(new UpdateUserRequest({email: user.email, name: user.name}))
        .then(res =>{
            this.notifyToast('List updated.') // Implement translation
        })
      
    }
    renderTableHeader = () => {
        return (
            <tr>
                <th>Email</th>
                <th>Name</th>
            </tr>
        )
    }

    renderTableData = () => {
        return this.state.users.map(user => {
            
            return (
                    <tr key={user.email}>
                        <td>{user.email}</td>
                        <td>{user.name}</td>
                    </tr>
             
            )
        })
    }

    renderTable = () => {
        
        if (this.state.loading) {
            return <Loading />
        }

        return (
            <Table className="cases-table">
                <thead>
                    {this.renderTableHeader()}
                </thead>
                <tbody>
                    {this.renderTableData()}
                </tbody>
            </Table>
        )
    }

    handleInviteUser = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        this.setState({ loading: true });
        this.userClient
            .partnerInvite(new UserResponse({ email: this.state.email, name: this.state.name }))
            .then(() => {
                this.userClient.partner().then(response => {
                    this.setState({ 
                        users: response, 
                        loading: false,
                        email: '',
                        name: ''
                    });
                })
            })
            this.setState({displayInviteBox: false})
            this.notifyToast('User invited.') // implement transaltion
    }
    
    public render() {
        // if (this.state.loading) return (<Loading />);
        return (
            <React.Fragment>
                <Row className="chart-row users">
                <Col lg={{size: 10, offset:1}} sm={{size: 10, offset:0}} className="mt-4" >
                    <h1 className="organisations-title">Users</h1>
                    <Button color="info" onClick={() => {this.setState({displayInviteBox: true})}}>
                        + Create User
                    </Button> 
                </Col>
                </Row>
                <Row className="cases-row">
                <Col lg={{size: 10, offset:1}} sm={{size: 10, offset:0}} >

                    {this.renderTable()}
                    <section className="col-4">
                    <Modal isOpen={this.state.displayInviteBox}>
                    <ModalHeader>
                        <b>Invite User</b>
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.handleInviteUser} className="w-50">
                                <FormGroup>
                                    <Label for="email">Email:</Label>
                                    <Input type="email" name="email" id="email" placeholder="Email"
                                        value={this.state.email}
                                        onChange={(e: any) => this.setState({ email: e.target.value })} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="name">Name:</Label>
                                    <Input type="text" name="name" id="name" placeholder="Name"
                                        value={this.state.name}
                                        onChange={(e: any) => this.setState({ name: e.target.value })} />
                                </FormGroup>
                                <FormGroup>
                                    <Button color="info">Invite</Button>
                                    <Button className="ml-2" color="danger" onClick={() =>{this.setState({displayInviteBox: false})}}>Go Back</Button>
                                
                                </FormGroup>
                        </Form>
                    </ModalBody>
                    </Modal>
                    </section>
                    </Col>

                    </Row>
            </React.Fragment>
        )
    }
}

const mapState = (state: ApplicationState) => ({
    partnerId: state && state.partner && state.partner.currentPartnerId,
} as IProps)

export default connect(mapState)(Settings)
function toast(action: string, arg1: { position: string; autoClose: number; hideProgressBar: boolean; closeOnClick: boolean; pauseOnHover: boolean; draggable: boolean; progress: undefined; }) {
    throw new Error('Function not implemented.');
}

