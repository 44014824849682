import * as React from 'react';
import { connect } from 'react-redux'
import '../../styles/NavMenu.css';
import {
    Collapse,
    Navbar,
    Nav,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown
} from 'reactstrap';
import { PartnerClient, PartnerResponse } from '../../ApiClient';
import { ApplicationState } from '../../store';
import { actionCreators } from '../../store/PartnerState';
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { ApiConfig } from '../../ApiConfig';

interface IProps {
    partnerId: string,
    partners: any,
    dispatch: any,

}
interface IState{
    partnerDropdownOpen: boolean;
}
class ChangePartner extends React.PureComponent<IProps,IState> {
    partnerClient = new PartnerClient(new ApiConfig())
    constructor(props: any) {
        super(props);
        this.state = {
            partnerDropdownOpen : false
        }
    }
    partnerToggle = () => {
        this.setState(prevState => ({
            partnerDropdownOpen: !prevState.partnerDropdownOpen
        }));
    };
    changeCurrentPartner = (partner: PartnerResponse) => {
        this.partnerClient
            .changePartner(partner.id!)
            .then(() => 
            {
                this.props.dispatch(actionCreators.setCurrentPartnerInformation(partner.id!, partner.name!))
                this.props.dispatch(push('/'))
                window.location.reload()
            });
    }

    renderPartners = () => {
        // if (this.props.partners.length < 1) {
        //     console.log('CASE HIT');
        //     // return [];
        //     return(
        //         <DropdownItem >
        //             No Partners Available
        //         </DropdownItem>
        //         )
        // }
        let items = this.props.partners.map((partner: PartnerResponse) => {

            return (
                <DropdownItem 
                key={partner.id}
                active={(partner.id === this.props.partnerId)}
                onClick={this.changeCurrentPartner.bind(this, partner, partner.name)}
                >
                    {partner.name}
                </DropdownItem>
            )
        })

        items = [
            <DropdownItem key="organisationsHeader" header>Options</DropdownItem>,
            ...items,
            <DropdownItem key="divider" divider></DropdownItem>]

        return items;
    }

    public render() {
        return (
                <Navbar expand="md">
                    <Collapse navbar>
                        <Nav className="ml-auto" navbar>

                        <Dropdown
                            isOpen={this.state.partnerDropdownOpen}
                            toggle={this.partnerToggle}
                            style={{zIndex:999, float:"right"}}
                        >
                             <DropdownToggle  outline color="secondary">
                                <FontAwesomeIcon icon={faBuilding} />
                            </DropdownToggle>
                            <DropdownMenu right>
                                    {this.renderPartners()}
                            </DropdownMenu>
                            </Dropdown>
                        </Nav>
                    </Collapse>
                </Navbar>
        );
    }
}

const mapState = (state: ApplicationState) => ({
    partnerId: state && state.partner && state.partner.currentPartnerId,
    partners: state && state.partner && state.partner.partners
} as IProps)

export default connect(mapState)(ChangePartner)
