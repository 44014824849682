import { Action, Reducer } from 'redux'
import { PartnerResponse } from '../ApiClient'

const SET_PARTNER_INFORMATION = 'SET_PARTNER_INFORMATION'
const SET_PARTNERS = 'SET_PARTNERS'

export interface PartnerState {
    currentPartnerId: string,
    currentPartnerName: string,
    partners: PartnerResponse[],
    loading: boolean
}

export interface SetPartnerInformationAction { 
    readonly type: 'SET_PARTNER_INFORMATION',
    partnerId: string,
    name: string
}

export interface SetPartnersAction {
    readonly type: 'SET_PARTNERS',
    partners: PartnerResponse[]
}

export type PartnerActions = SetPartnerInformationAction | SetPartnersAction;

export const actionCreators = {
    setCurrentPartnerInformation: (partnerId: string, name: string) => (
        { 
            type: SET_PARTNER_INFORMATION,
            partnerId: partnerId,
            name: name
        } as SetPartnerInformationAction),
    setPartners: (partners: PartnerResponse[]) => (
        {
            type: SET_PARTNERS,
            partners: partners
        } as SetPartnersAction),
};

export const reducer: Reducer<PartnerState> = (state: PartnerState | undefined, incomingAction: Action): PartnerState => {
    if (state === undefined) {
        return { currentPartnerId: '', currentPartnerName: '', partners: [], loading: true }
    }

    const action = incomingAction as PartnerActions
    switch (action.type) {
        case SET_PARTNER_INFORMATION:
            return {
                currentPartnerId: (action as SetPartnerInformationAction).partnerId,
                currentPartnerName: (action as SetPartnerInformationAction).name,
                partners: state.partners,
                loading: true
            }
        case SET_PARTNERS:
            let org = (action as SetPartnersAction).partners.find(p => p.selected === true);

            if (org === undefined)
                org = (action as SetPartnersAction).partners[0];

            return {
                currentPartnerId: org.id!,
                currentPartnerName: org.name!,
                partners: (action as SetPartnersAction).partners,
                loading: true
            }
        default:
            return state
    }
};