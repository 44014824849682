import * as React from 'react';
import { connect } from 'react-redux';
import { Container, Row } from 'reactstrap';
import NavMenuTop from './NavMenuTop';
import  NavMenuSide  from './NavMenuSide';
import { actionCreators } from '../store/PartnerState'
import { ApiConfig } from '../ApiConfig';
import { OrganisationClient, PartnerClient } from '../ApiClient';
import { Loading } from './loading/loading'
import '../styles/custom.scss'

interface IProps {
    dispatch: any
}

interface IState {
    children?: React.ReactNode,
    loading: boolean
}

class Layout extends React.PureComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props)

        this.state = {
            children: this.props.children,
            loading: true
        }
    }

    componentDidMount() {
        const client = new PartnerClient(new ApiConfig());
        client.partnerGet().then(response => {
            this.props.dispatch(actionCreators.setPartners(response))
            this.setState({ loading: false })
        })
    }

    public render() {
        if (this.state.loading){
            return <Loading />
        }
        return (
            <React.Fragment>
                {/* <NavMenuTop /> */}
                <Container fluid={true} id="mainContainer" className="h-100"> 
                    <Row className="h-100">
                        <NavMenuSide />
                        <div className="col-md-10 offset-md-2 h-100" id="caseContainer">
                            {this.props.children}
                        </div>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

export default connect()(Layout);
