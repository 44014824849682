import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import './styles/custom.scss'
import Settings from './pages/Settings';
import Organisations from './pages/Organisations';
import CreateOrganisation from './pages/CreateOrganisation';
import Partners from './pages/Partners';
import CreatePartner from './pages/CreatePartner';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux'
import { ApplicationState } from './store';
import { Loading } from './components/loading/loading';
import UsersTable from './pages/UsersTable';
import Users from './pages/Users';


function App() {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently
  } = useAuth0();

  const key = useSelector((state: ApplicationState) => state && state.partner && state.partner.currentPartnerId)

  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  if (isLoading) {
    return <Loading />
  }

  if (isAuthenticated) {
    return (
      <Layout>
        <Route exact path='/' component={Organisations} key={0}/>
        <Route exact path='/partners' component={Partners} key={1} />
        <Route exact path="/partners/create" component={CreatePartner} key={2} />
        <Route exact path='/organisations' component={Organisations} key={3} />
        <Route exact path='/organisations/create' component={CreateOrganisation} key={4} />
        <Route exact path='/settings' component={Settings} key={5}/>
        <Route exact path='/users/:id' component={UsersTable} key={6}/>
        <Route exact path='/users' component={Users} key={7}/>


        
      </Layout>
    );
  } else {
    loginWithRedirect();
    return <Loading />
  }
}

export default App;
