import 'bootstrap/dist/css/bootstrap.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import configureStore from './store/configureStore';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const history = createBrowserHistory({ basename: baseUrl });

const store = configureStore(history);

export default store;

ReactDOM.render(

    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Auth0Provider
                domain="legaltech.eu.auth0.com"
                clientId="v5VsZDDC5v9MwhCHk97IPg0XC91PMsFy"
                redirectUri={window.location.origin}
                audience="https://api.whistle.360-advisor.com"
                scope="read:current_user update:current_user_metadata"
                useRefreshTokens={true}
                cacheLocation="localstorage"
            >
                <App />
            </Auth0Provider>
        </ConnectedRouter>
    </Provider>
    ,
    document.getElementById('root'));

