import * as React from 'react';
import _ from 'lodash';
import { UserClient, UserRoleResponse, DeleteUserRequest, UpdateUserNameRequest } from '../ApiClient';
import { ApiConfig } from '../ApiConfig';
import '../styles/custom.scss'
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { Loading } from '../components/loading/loading'
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';
import { EmptyState } from '../components/emptyState/emptyState';


export interface IState {
    organisationId: string,
    users: UserRoleResponse[],
    headers: string[],
    loading: boolean,
    isAdmin: boolean,
    currUserId: string,
    displayDeleteUser: boolean,
    userToEditId: string,
    userToEditName: string,
    userToEditEmail: string,
    newName: string
}

interface IProps {
    organisationId: string,
    history: any,
}

class UsersTable extends React.Component<IProps, IState> {

    userClient = new UserClient(new ApiConfig());

    constructor(props: IProps) {
        super(props);
        this.state = {
            organisationId: this.props.organisationId,
            users: [],
            headers: ["#", "Name", "Email", "Role"],
            loading: true,
            isAdmin: false,
            currUserId: '',
            displayDeleteUser: false,
            userToEditId: '',
            userToEditName: '',
            userToEditEmail: '',
            newName: '',
        }
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.organisationId !== this.props.organisationId) {
            var organisationId = window.location.pathname.split("/");
            this.userClient.organsationWithRoleByOrganisationId(organisationId[2]).then(response => {
                this.setState({ users: response });
            })
            this.userClient.currentUser().then(response => {

                if (response.isAdmin == true) {
                    this.setState({ isAdmin: true, currUserId: response.id!, loading: false })
                } else {
                    this.setState({ isAdmin: false, currUserId: response.id!, loading: false })
                }
            })
        }
    }

    public componentDidMount(): void {
        this.setState({ organisationId: this.props.organisationId })
        var organisationId = window.location.pathname.split("/");
        this.userClient.organsationWithRoleByOrganisationId(organisationId[2]).then(response => {
            this.setState({ users: response });
        })
        this.userClient.currentUser().then(response => {
            if (response.isAdmin == true) {
                this.setState({ isAdmin: true, currUserId: response.id!, loading: false })
            } else {
                this.setState({ isAdmin: false, currUserId: response.id!, loading: false })
            }
        })
    }
    handleDeleteUser() {
        this.userClient.user(
            new DeleteUserRequest(
                {
                    id: this.state.userToEditId
                }))
            .then(() => {
                // this.notifyToast("success");
                window.location.reload();
            })
    }
    trimText(input: string) {
        return input.substring(0, 10);
    }
    handleRoleId(input: string) {
        switch (input) {
            case "00000000-0000-0000-0000-000000000001":
                return "Administrator"
            case "00000000-0000-0000-0000-000000000002":
                return "CaseWorker"
        }
    }
    renderTableData() {
        return this.state.users.map((users) => {

            return (
                <tr key={users.id}>
                    <td>{this.trimText(users.id!)}</td>
                    <td>{users.name}</td>
                    <td>{users.email}</td>
                    <td>{this.handleRoleId(users.roleId!)}</td>
                    <td><Button color="warning" onClick={() => this.setState({ displayDeleteUser: true, userToEditId: users.id!, userToEditEmail: users.email!, userToEditName: users.name! })}>Edit</Button></td>
                </tr>
            )
        });
    }
    renderTableHeader() {
        let headers = this.state.headers;
        return <tr>
            <th scope="col">{headers[0]}</th>
            <th scope="col">{headers[1]}</th>
            <th scope="col">{headers[2]}</th>
            <th scope="col">{headers[3]}</th>
            <th></th>
        </tr>
    }
    handleChangeName = (e: any) => this.setState({ newName: e.target.value });

    saveChanges() {
        console.log('name: ', this.state.newName);
        if (this.state.newName.length > 0) {
            this.userClient.updateUserName(new UpdateUserNameRequest({ id: this.state.userToEditId, name: this.state.newName }))
            .then(res => {
                window.location.reload();
            }).catch(() => {
            });
            this.setState({displayDeleteUser: false})
        }
    }
    renderTable = () => {
        if (this.state.loading) {
            return <Loading />
        }

        if (this.state.users.length == 0) {
            return <EmptyState text="This organisation does not have any users" />
        }

        return (
            <Table className="cases-table" >
                <thead>
                    {this.renderTableHeader()}
                </thead>
                <tbody>
                    {this.renderTableData()}
                </tbody>
            </Table>
        )
    }
    public render() {
        if (this.state.loading) return (<Loading />);
        return (
            <>
            <Row className="chart-row">
                    <Col lg={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 0 }} className="mt-4" >
                        <h1 className="organisations-title">Users</h1>
                    </Col>
                </Row>
                <Row className="cases-row">
                    <Col lg={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 0 }} >
                        {this.renderTable()}
                    </Col>
                </Row>
                {/* <table className="table table-hover" id="casesTable">
                    <tbody>
                        {this.renderTableHeader()}
                        {this.renderTableData()}
                    </tbody>
                </table> */}
                <section className="col-4">
                    <Modal isOpen={this.state.displayDeleteUser}>
                        <ModalHeader>
                            <b>You can edit and delete {this.state.userToEditName}</b>
                        </ModalHeader>
                        <ModalBody>
                            <b>Deletion is permanent</b>
                            <br />
                            <br />
                            {/* <Label for="email">Email</Label>
                            <Input type="email" placeholder={this.state.userToEditEmail}
                                value={this.state.userToEditEmail} disabled
                            /> */}
                            <br />
                            <Label for="name">Name</Label>
                            <input
                                onChange={e => this.handleChangeName(e)}
                                type="text"
                                className="form-control"
                                name="title"
                                placeholder={this.state.userToEditName}
                            />
                            {/* <Input type="text" placeholder={this.state.userToEditName} /> */}
                            <br />
                            <Button className="ml-2" color="success" onClick={() => { this.saveChanges() }}>Save Changes</Button>
                            <Button className="ml-2" color="warning" onClick={() => { this.setState({ displayDeleteUser: false }) }}>Go back</Button>
                            <Button color="danger" onClick={() => this.handleDeleteUser()} style={{ float: 'right' }}>Delete</Button>

                        </ModalBody>
                    </Modal>

                </section>
            </>
        )
    }
}

const mapState = (state: ApplicationState) => ({
    organisationId: state && state.organisation && state.organisation.currentOrganisationId,
} as IProps)

export default connect(mapState)(UsersTable)


