import * as React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
interface Props {
    language: any | undefined
}
export const LogoutButton: React.FC<{}> = ({}) => {
    const { logout } = useAuth0();
    return (
        <a
            onClick={() =>
                logout({
                    returnTo: window.location.origin,
                })
            }
        >
            <FontAwesomeIcon icon={faSignOutAlt} size="lg" className="mt-3"/>
            {/* <TranslatableText translationKey="logout" /> */}
        </a>
    );
};  

// const mapState = (state: ApplicationState) => ({
//     language: state && state.language.language_resources
// } as Props)

export default LogoutButton
