import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';
import { DeleteUserRequest, GetAllUsersResponse, UpdateUserNameRequest, UserClient } from '../ApiClient';
import { ApiConfig } from '../ApiConfig';
import { EmptyState } from '../components/emptyState/emptyState';
import { Loading } from '../components/loading/loading';
import { ApplicationState } from '../store';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
export interface IState {
    partnerId: string,
    users: GetAllUsersResponse[],
    loading: boolean,
    userId: string,
    displayDeleteUser: boolean,
    newName: string,
    userToEditName: string
}

interface IProps {
    partnerId: string,
    history: any,
}

class Users extends React.Component<IProps, IState> {

    userClient = new UserClient(new ApiConfig())

    constructor(props: IProps) {
        super(props);
        this.state = {
            partnerId: this.props.partnerId,
            users: [],
            userId: "",
            loading: true,
            displayDeleteUser: false,
            newName: "",
            userToEditName: "",
        }
    }

    public componentDidMount(): void {
        this.userClient.allUsers()
            .then(response => {
                this.setState({ users: response, loading: false })
            })
    }
    renderTableHeader = () => {
        return (
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Organisation</th>
                <th>Role</th>
                <th>Created On</th>
                <td></td>
            </tr>
        )
    }

    renderTableData = () => {
        return this.state.users.map(user => {
            return (

                <tr key={user.userName} >
                    <td>{user.userName}</td>
                    <td>{user.email}</td>
                    <td>{user.organisationName}</td>
                    <td>{user.role}</td>
                    <td>{moment(user.createdOn).format('llll')}</td>
                    <td><span onClick={() => this.handleDeleteUser(user.userId!)}><FontAwesomeIcon icon={faTrash} color="#495057" style={{cursor: 'pointer'}} /></span></td>
                </tr>
            )
        })
    }

    handleDeleteUser(userId: string) {
        this.userClient.user(
            new DeleteUserRequest(
                {
                    id: userId
                }))
            .then(() => {
                window.location.reload();
            }).catch(e =>{
                console.log('Exception:', e);
            })
    }
    trimText(input: string) {
        return input.substring(0, 10);
    }
    renderTable = () => {
        if (this.state.loading) {
            return <Loading />
        }
        if (this.state.users.length == 0) {
            return <EmptyState text="You currently have no sub partners" />
        }
        return (
            <Table className="cases-table" >
                <thead>
                    {this.renderTableHeader()}
                </thead>
                <tbody>
                    {this.renderTableData()}
                </tbody>
            </Table>
        )
    }
    handleChangeName = (e: any) => this.setState({ newName: e.target.value });

    saveChanges() {
        if (this.state.newName.length > 0) {
            this.userClient.updateUserName(new UpdateUserNameRequest({ id: this.state.userId, name: this.state.newName }))
                .then(res => {
                    window.location.reload();
                }).catch(() => {
                });
            this.setState({ displayDeleteUser: false })
        }
    }
    public render() {
        return (
            <React.Fragment>
                <>
                    <Row className="chart-row">
                        <Col lg={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 0 }} className="mt-4" >
                            <h1 className="organisations-title">Users</h1>
                        </Col>
                    </Row>
                    <Row className="cases-row">
                        <Col lg={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 0 }} >
                            {this.renderTable()}
                        </Col>
                    </Row>
                    <section className="col-4">
                        <Modal isOpen={this.state.displayDeleteUser}>
                            <ModalHeader>
                                <b>You can edit and delete {this.state.userToEditName}</b>
                            </ModalHeader>
                            <ModalBody>
                                <b>Deletion is permanent</b>
                                <br />
                                <br />
                                <br />
                                <Label for="name">Name</Label>
                                <input
                                    onChange={e => this.handleChangeName(e)}
                                    type="text"
                                    className="form-control"
                                    name="title"
                                    placeholder={this.state.userToEditName}
                                />
                                <br />
                                <Button className="ml-2" color="success" onClick={() => { this.saveChanges() }}>Save Changes</Button>
                                <Button className="ml-2" color="warning" onClick={() => { this.setState({ displayDeleteUser: false }) }}>Go back</Button>
                                <Button color="danger" onClick={() => this.handleDeleteUser()} style={{ float: 'right' }}>Delete</Button>

                            </ModalBody>
                        </Modal>

                    </section>
                </>

            </React.Fragment>
        )
    }
}

const mapState = (state: ApplicationState) => ({
    partnerId: state && state.partner && state.partner.currentPartnerId,
} as IProps)

export default connect(mapState)(Users)
