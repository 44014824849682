import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';

export class ApiConfig {

    client: Auth0Client | undefined;

    async getAuthToken() {
        if (this.client === undefined){
            this.client = await createAuth0Client({
                domain: "legaltech.eu.auth0.com",
                client_id: "v5VsZDDC5v9MwhCHk97IPg0XC91PMsFy",
                redirect_uri: process.env.REACT_APP_APPLICATION_URL,
                audience: "https://api.whistle.360-advisor.com",
                scope: "read:current_user update:current_user_metadata",
                useRefreshTokens: true,
                cacheLocation: "localstorage"
            })
        }
        return await this.client.getTokenSilently();
    }
}