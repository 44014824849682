import { faCogs, faHandshake, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row
} from 'reactstrap';
import { UserClient } from '../ApiClient';
import { ApiConfig } from '../ApiConfig';
import { Loading } from '../components/loading/loading';
import Logo from '../ressources/Whistleblower-grafik.svg';
import { ApplicationState } from '../store';
import '../styles/NavMenu.css';
import { LogoutButton } from './Logout';
import ChangePartner from './partner/ChangePartner';

interface IProps {
  partnerId: string,
  partnerName: string,

}
let userClient = new UserClient(new ApiConfig());

const NavMenuSide: React.FC<IProps> = ({ partnerId, partnerName }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [displayUsers, setDisplayUsers] = useState<boolean>(false);

  const getCurrentUserRole = async () => {
    await userClient.currentUserRole()
      .then(res => {
        setDisplayUsers(res.isLegalTechPartner!)
        setIsLoading(false)
      }).catch(e => {
        console.log('Exception happend in .currentUserRole()', e);
        setDisplayUsers(false)
        setIsLoading(false)
      })
  }
  getCurrentUserRole();
  if (isLoading) {
    return <Loading />
  }
  return (
    <div className="col-2" id="left-navigation">
      <Nav style={{ textAlign: 'left' }} vertical expand="md">
        <div id="nav-top-segment">
          <span className="company-logo">
            {/* <FontAwesomeIcon icon={faBuilding} size="lg" /> */}
            <img className="whistle-logo mt-2" src={Logo} alt="" />

            <hr />
          </span>
          <Link to="/" className="mt-4 mb-4 company-name">{partnerName}</Link>
          <hr />
        </div>
        <NavItem className="mt-2 side-nav-item">
          <NavLink tag={Link} to="/organisations" className="side-nav-link">
            <FontAwesomeIcon icon={faHome} size="lg" className="mr-4" />
            <span className="side-nav-text">
              Organisations
            </span>
          </NavLink>
        </NavItem>
        <NavItem className="mt-2 side-nav-item">
          <NavLink tag={Link} to="/partners" className="side-nav-link">
            <FontAwesomeIcon icon={faHandshake} size="lg" className="mr-4" />
            <span className="side-nav-text">
              Partners
            </span>
          </NavLink>
        </NavItem>
      </Nav>
      <hr />
      <Nav style={{ textAlign: 'left' }} vertical>
        <NavItem className="mt-2 side-nav-item">
          <NavLink className="side-nav-link" tag={Link} to="/settings">
            <FontAwesomeIcon icon={faCogs} size="lg" className="mr-3" />
            <span className="side-nav-text">
              Settings
            </span>
          </NavLink>
        </NavItem>
      </Nav>
      {displayUsers &&
        <Nav style={{ textAlign: 'left' }} vertical>
          <NavItem className="mt-2 side-nav-item">
            <NavLink className="side-nav-link" tag={Link} to="/users">
              <FontAwesomeIcon icon={faCogs} size="lg" className="mr-3" />
              <span className="side-nav-text">
                Users
              </span>
            </NavLink>
          </NavItem>
        </Nav>
      }
      {/* Bottom nav below */}
      <div className="col-2 bottom-nav" >
        <Row>
          <Col md={4} sm={2} xs={8} className="mt-4 ">
            <ChangePartner />
          </Col>
          <Col md={4} sm={2} xs={8} className="mt-4">
            {/* <ChangeLanguage /> */}
          </Col>
          <Col md={4} sm={2} xs={8} className="mt-4 pl-4 bottom-nav-signout">
            <LogoutButton />
          </Col>
        </Row>
      </div>
    </div>
  );
}


const mapState = (state: ApplicationState) => ({
  partnerId: state && state.partner && state.partner.currentPartnerId,
  partnerName: state && state.partner && state.partner.currentPartnerName,
} as IProps)

export default connect(mapState)(NavMenuSide)
