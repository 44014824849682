import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';
import {CaseClient, CaseResponse, CreateOrganisationRequest, DeleteCaseRequest, PartnerClient, PartnerResponse, UpdateCaseRequest, UpdateUserRequest, UserClient, UserResponse} from '../ApiClient';
import { ApiConfig } from '../ApiConfig';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { Loading } from '../components/loading/loading'
import { EmptyState } from '../components/emptyState/emptyState'
import { LinkButton } from '../components/generic/link-button'
import { FormGroup, Button, Form, Input, Label, Table, Row, Col } from 'reactstrap';

export interface IState {
    partnerId: string,
    accessiblePartners: PartnerResponse[],
    partners: PartnerResponse[],
    loading: boolean,
}

interface IProps {
    partnerId: string,
    partners: PartnerResponse[],
    history: any,
}

class Partners extends React.Component<IProps, IState> {

    partnerClient = new PartnerClient(new ApiConfig())
    
    constructor(props: IProps) {
        super(props);
        this.state = {
            partnerId: this.props.partnerId,
            accessiblePartners : this.props.partners,
            partners: [],
            loading: true,
        }
    }

    public componentDidMount(): void {
        this.partnerClient.subPartners().then(response => {
            this.setState({ partners: response, loading: false });
        })
    }

    renderTableHeader = () => {
        return (
            <tr>
                <th>Partner name</th>
                <th>Tin Number</th>
                <th>Email</th>
            </tr>
        )
    }

    renderTableData = () => {
        return this.state.partners.map(partner => {
            return (
                <tr key={partner.id}>
                    <td>{partner.name}</td>
                    <td>{partner.tinNumber}</td>
                    <td>{partner.email}</td>
                </tr>
            )
        })
    }

    renderTable = () => {
        
        if (this.state.loading) {
            return <Loading />
        }

        if (this.state.partners.length == 0){
            return <EmptyState text="You currently have no sub partners" />
        }

        return (
            <Table className="cases-table">
                <thead>
                    {this.renderTableHeader()}
                </thead>
                <tbody>
                    {this.renderTableData()}
                </tbody>
            </Table>
        )
    }


    public render() {
        return (
            <React.Fragment>
                <Row className="chart-row partner">
                <Col lg={{size: 10, offset:1}} sm={{size: 10, offset:0}} className="mt-4" >
                    <h1 className="organisations-title">Partners</h1>
                    <LinkButton  to="/partners/create" text="Create Partner" /> 
                </Col>
                </Row>
                <Row className="cases-row">
                    <Col lg={{size: 10, offset:1}} sm={{size: 10, offset:0}} >
                        {this.renderTable()}
                    </Col>
                </Row>
                
                
            </React.Fragment>
        )
    }
}

const mapState = (state: ApplicationState) => ({
    partnerId: state && state.partner && state.partner.currentPartnerId,
    partners: state && state.partner && state.partner.partners
} as IProps)

export default connect(mapState)(Partners)
