import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { OrganisationClient, OrganisationResponse, PartnerClient, DeleteOrganisationRequest } from '../ApiClient';
import { ApiConfig } from '../ApiConfig';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { Loading } from '../components/loading/loading'
import { EmptyState } from '../components/emptyState/emptyState'
import { LinkButton } from '../components/generic/link-button'
import { FormGroup, Button, Form, Input, Label, Table, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';

export interface IState {
    partnerId: string,
    accessibleOrganisations: OrganisationResponse[],
    organisations: OrganisationResponse[],
    loading: boolean,
    displayDeleteOrganisation: boolean,
    orgToDeleteId: string,
    orgToDeleteName: string
}

interface IProps {
    partnerId: string,
    history: any,
}

class Organisations extends React.Component<IProps, IState> {

    partnerClient = new PartnerClient(new ApiConfig())
    organisationClient = new OrganisationClient(new ApiConfig())

    constructor(props: IProps) {
        super(props);
        this.state = {
            partnerId: this.props.partnerId,
            accessibleOrganisations: [],
            organisations: [],
            loading: true,
            displayDeleteOrganisation: false,
            orgToDeleteId: '',
            orgToDeleteName: '',
        }
    }

    public componentDidMount(): void {
        this.partnerClient.organisations().then(response => {
            this.setState({ organisations: response });

            this.organisationClient.organisationGet().then(response => {
                this.setState({ accessibleOrganisations: response, loading: false });
            })
        })
    }
    renderTableHeader = () => {
        return (
            <tr>
                <th>Organisation name</th>
                <th>Tin Number</th>
                <th></th>

            </tr>
        )
    }

    renderTableData = () => {
        return this.state.organisations.map(organisation => {
            return (

                <tr key={organisation.id} >
                    <td>{organisation.name}</td>
                    <td>{organisation.tinNumber}</td>
                    <td><Button color="info" onClick={() => this.props.history.push("/users/"+organisation.id)}>Users</Button></td>
                    
                    <td><Button color="danger" onClick={() => this.setState({ displayDeleteOrganisation: true, orgToDeleteId: organisation.id!, orgToDeleteName: organisation.name! })}>Delete</Button></td>

                </tr>
            )
        })
    }

    renderTable = () => {
        if (this.state.loading) {
            return <Loading />
        }

        if (this.state.organisations.length == 0) {
            return <EmptyState text="You currently have no sub partners" />
        }

        return (
            <Table className="cases-table" >
                <thead>
                    {this.renderTableHeader()}
                </thead>
                <tbody>
                    {this.renderTableData()}
                </tbody>
            </Table>
        )
    }
    handleDeleteOrganisation() {
        if (this.state.orgToDeleteId.length > 0) {
            this.organisationClient.deleteOrganisation(
                new DeleteOrganisationRequest(
                    {
                        organisationId: this.state.orgToDeleteId
                    }))
                .then(res => {
                    window.location.reload();
                })
        }
    }
    public render() {
        return (
            <React.Fragment>
                <Row className="chart-row">
                    <Col lg={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 0 }} className="mt-4" >
                        <h1 className="organisations-title">Organisations</h1>
                        <LinkButton to="/organisations/create" text="Create Organisation" />
                    </Col>
                </Row>
                <Row className="cases-row">
                    <Col lg={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 0 }} >
                        {this.renderTable()}
                    </Col>
                </Row>

                <section className="col-4">
                    <Modal isOpen={this.state.displayDeleteOrganisation}>
                        <ModalHeader>
                            <b>Are you sure you wish to delete {this.state.orgToDeleteName}</b>
                        </ModalHeader>
                        <ModalBody>
                            <b>This action is permanent</b>
                            <br />
                            <br />
                            <br />
                            <Label for="name">Name</Label>
                            <Input type="text" placeholder={this.state.orgToDeleteName} disabled />
                            <br />
                            <Button className="float-right" color="warning" onClick={() => { this.setState({ displayDeleteOrganisation: false }) }}>Go back</Button>
                            <Button className="float-right" color="danger"  onClick={() => this.handleDeleteOrganisation()} style={{ float: 'right' }}>Delete</Button>

                        </ModalBody>
                    </Modal>
                </section>
            </React.Fragment>
        )
    }
}

const mapState = (state: ApplicationState) => ({
    partnerId: state && state.partner && state.partner.currentPartnerId,
} as IProps)

export default connect(mapState)(Organisations)
