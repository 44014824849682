import * as React from 'react';
import { IBaseStepProps } from "./Interfaces";
import { Stats } from './Stats';

interface Props extends IBaseStepProps {
    header: string,
    step: number,
    children: any,
    finish?: Function,
    validate: Function
}

export const WizardStep: React.FC<Props> = (props) => {
    return (
        <div className="col-6">
            <h3>{props.header}</h3>
            {props.children}
            <Stats {...props} />
        </div>
    );
};  